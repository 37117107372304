import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { CardPayment } from "../CardPayment";
import { criarFormatador } from "utils/moneyformatter";
import "./styles.css";

export const SuccessPage = ({ clientData, isPix }) => {
  const formatador = criarFormatador();

  const obterDataAtual = () => {
    const data = new Date();
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const successContent = (
    <div className="success-container">
      <div className="success-header">
        <FaRegCheckCircle className="success-icon" />
        <h1>Compra Realizada com Sucesso!</h1>
      </div>
      <div className="success-body">
        {!clientData || !clientData.pagamento || !clientData.cliente ? (
          <p>Erro: Dados do pagamento ou cliente não disponíveis.</p>
        ) : (
          <>
            <p>
              Obrigado por sua compra! Seus detalhes de pagamento foram processados com sucesso.
            </p>
            <p>Detalhes da Compra:</p>
            <ul>
              <li><strong>Produto:</strong> {clientData.pagamento.titulo}</li>
              <li><strong>Nome do Titular:</strong> {clientData.cliente.nome}</li>
              <li><strong>Data:</strong> {obterDataAtual()}</li>
              <li><strong>Total:</strong> {formatador.format(clientData.pagamento.valor)}</li>
            </ul>
            <p>Informações do vendedor:</p>
            <ul>
              <li><strong>Nome:</strong> {clientData.nomeProfissional}</li>
              <li><strong>Cpf:</strong> {clientData.cpfProfissional}</li>
            </ul>
          </>
        )}
      </div>
    </div>
  );

  if (!isPix) {
    return <CardPayment>{successContent}</CardPayment>;
  }

  return successContent;
};

import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";

import { _buscarDocumento } from "services/DocumentoService";
import { HiOutlineDocument, HiOutlineDocumentDownload } from "react-icons/hi";
import { Button } from "components/ui";

import 'react-quill/dist/quill.snow.css';
import ModalView from "./ModalView";

import QRCode from "qrcode"
import { _fbGetFile } from "services/FirebaseService";

export default function Documentos() {
    let { uuidDocumento } = useParams();
    const [openModalView, setOpenModalView] = useState(false)
    const [qrCodeBase64, setQrCodeBase64] = useState('');
    const [anexos, setAnexos] = useState([])

    const [data, setData] = useState({
        documento: {}
    })

    const imgExt = [
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
        '.bmp',
        '.svg',
        '.tiff',
        '.webp',
    ];


    async function getData() {
        const response = await _buscarDocumento(uuidDocumento)
        setData(response.data)

        const { documento } = response.data

        if(documento.anexos) {
            let urls = []
            const list = JSON.parse(documento.anexos)

            await Promise.all(list.map(async item => _fbGetFile(item).then(res => urls.push(res))))

            setAnexos(urls)
        }
    }

    async function generateQrCode() {
        const QRbase64 = await new Promise((resolve, reject) => {
            QRCode.toDataURL(location.href, (err, code) => {
                if (err) {
                    reject(reject);
                    return;
                }
                resolve(code);
            });
        });
        setQrCodeBase64(QRbase64);
    }

    useEffect(() => {
        getData()
        generateQrCode()
    },[uuidDocumento])

    async function handleDownloadItem(anexo, ext, i){
        fetch(anexo)
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `anexo-${i}${ext}`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
    }

    return (
        <>
            <ModalView
                isOpen={openModalView}
                onClose={() => setOpenModalView(false)}
                data={data}
                qrCodeBase64={qrCodeBase64}
            />
            <div
                style={{ backgroundImage: "url(img/others/ils_12.png)" }}
                className="app-layout-blank flex flex-col justify-center items-center min-h-screen background-image-login pb-5"
            >

                <div className="max-w-2xl w-full bg-white py-4 px-8 rounded-lg m-4">
                    <div className="flex justify-between pb-2">
                        <img  src={data.urlLogo ? data.urlLogo : data.urlFoto}  style={{ maxWidth: 100, objectFit: 'contain' }} id="logo" />
                        <div>
                        <h5 className="font-light">{data.documento.titulo}</h5>
                        <p>{data.nome}</p>
                        <p>{data.profissao}</p>
                        <p>{data.email}</p>
                        <p>{data.telefone}</p>
                        </div>

                    </div>
                    <article className="my-4 ql-editor" style={{ height: 'auto' }} dangerouslySetInnerHTML={{ __html: data.documento.texto }} >
                    </article>

                    <h5 className="font-light">Anexo</h5>
                    <div className="flex gap-2 items-center py-5 flex-wrap">
                        {anexos.map((anexo, i) => {
                                const ext = anexo.substring(anexo.lastIndexOf(".")).split("?")[0]

                                return (
                                    <div
                                        key={i}
                                        onClick={() => handleDownloadItem(anexo, ext, i + 1)}
                                        className="w-28 h-36 rounded-lg border border-gray-500 cursor-pointer"
                                    >
                                        {imgExt.includes(ext) ? (
                                            <img
                                                className="upload-file-image w-36 h-36 rounded-lg object-cover"
                                                src={anexo}
                                                alt="file preview"
                                            />
                                        ) : (
                                            <div className="h-full flex flex-col items-center justify-center">
                                                <HiOutlineDocument width={64} size={64} />
                                                <strong>{ext}</strong>
                                            </div>
                                        )}
                                </div>
                            )
                        })}
                    </div>

                    <div className="flex justify-between w-full">

                                <img
                                    class="w-24 h-24"
                                    src={qrCodeBase64}
                                    alt=""
                                />

                                <p className="max-w-xs">
                                    Este documento foi gerado por Corpora - Saúde inteligente e pode ser
                                    verificado no QR code ao lado.
                                </p>

                    </div>
                </div>
                <Button variant="solid" icon={<HiOutlineDocumentDownload />} onClick={() => setOpenModalView(true)}>Baixar documento</Button>
            </div>
        </>
    )
}
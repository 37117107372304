import { AiOutlineUser } from "react-icons/ai";
import { PiTrashDuotone, PiWhatsappLogo } from "react-icons/pi";
import ReactTooltip from "react-tooltip";
import { formatWhatsNumber } from "utils/format";
import { Link } from "react-router-dom";

function ContactListDesktop({ contacts, handleDeleteContact }) {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider font-normal">
            Nome
          </th>
          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider font-normal">
            Telefone
          </th>
          <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase tracking-wider font-normal">
            Relação
          </th>
          <th className="px-6 py-3" />
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {contacts.map((contact, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex items-center">
                {contact.clienteRelacionado ? (
                  <Link
                    reloadDocument
                    to={`/cliente/${contact.clienteRelacionado.id}?pg=profile`}
                    className="text-sm font-medium text-gray-400 hover:text-gray-500 flex justify-center items-center"
                  >
                    <AiOutlineUser
                      data-tip="Ver perfil"
                      data-place="top"
                      data-effect="solid"
                      className="w-4 h-4 mr-2 text-indigo-600 cursor-pointer outline-none"
                      data-offset="{'top': -10}"
                    />
                    <ReactTooltip
                      backgroundColor="none"
                      textColor="#9CA3AF"
                      fontSize="12px"
                    />
                    {contact.clienteRelacionado.nome}
                  </Link>
                ) : (
                  <div className="text-sm font-medium text-gray-400">
                    {contact.nome}
                  </div>
                )}
              </div>
            </td>
            <td className="px-6 py-4 flex items-center whitespace-nowrap text-gray-400">
              <PiWhatsappLogo className="mr-1" color="#128C7E" />
              <a
                className=" cursor-pointer text-sm flex items-center  hover:text-gray-500"
                target="_blank"
                href={`https://wa.me/${formatWhatsNumber(
                  contact.clienteRelacionado
                    ? contact.clienteRelacionado.telefone
                    : contact.telefone
                )}`}
                rel="noreferrer"
              >
                {contact.clienteRelacionado
                  ? contact.clienteRelacionado.telefone
                  : contact.telefone}
              </a>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-gray-400">
              <div className="text-sm">{contact.relacao}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <div
                onClick={() => handleDeleteContact(index)}
                className="cursor-pointer text-red-400 hover:text-red-500"
              >
                <PiTrashDuotone
                  className="w-5 h-5 outline-none"
                  data-tip="Deletar"
                  data-place="top"
                  data-offset="{'top': -5}"
                />
                <ReactTooltip
                  backgroundColor="none"
                  textColor="#9CA3AF"
                  fontSize="8px"
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ContactListDesktop;

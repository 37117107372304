import React from "react";
import { HiExternalLink } from "react-icons/hi";
import { CiInstagram, CiYoutube } from "react-icons/ci";
import { BiLogoWhatsapp, BiLogoTiktok } from "react-icons/bi";

const ContactLinks = ({ dadosTerapeuta, links = {} }) => {
  const formatWhatsNumber = (numero) => {
    return numero
      ?.replace("+", "")
      .replace(/\s/g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/\-/g, "");
  };

  console.log(links)

  return (
    <div className="flex flex-col w-64">
      {dadosTerapeuta.telefone && (
        <a
          href={`https://wa.me/${formatWhatsNumber(
            dadosTerapeuta.telefone
          )}?text=Encontrei+seu+perfil+no+Corpora!`}
        >
          <p className="text-md p-2 mb-4 rounded shadow-md text-grey-900 font-bold border transition-colors hover:border-[#3730a3]">
            <BiLogoWhatsapp className="inline" />
            Whatsapp
          </p>
        </a>
      )}

      {links && links.instagram?.link && (
        <a href={links && links.instagram.link}>
          <p className="text-md p-2 mb-4 rounded shadow-md text-grey-900 font-bold border transition-colors hover:border-[#3730a3]">
            <CiInstagram className="inline" />
            {links && links.instagram.nome}
          </p>
        </a>
      )}

      {links && links.tiktok?.link && (
        <a href={links && links.tiktok.link}>
          <p className="text-md p-2 mb-4 rounded  shadow-md text-grey-900 font-bold border transition-colors hover:border-[#3730a3]">
            <BiLogoTiktok className="inline" />
            {links && links.tiktok.nome}
          </p>
        </a>
      )}

      {links && links.youtube?.link && (
        <a href={links && links.youtube.link}>
          <p className="text-md p-2 mb-4 rounded  shadow-md text-grey-900 font-bold border transition-colors hover:border-[#3730a3]">
            <CiYoutube className="inline" />
            {links && links.youtube.nome}
          </p>
        </a>
      )}

      {links && links.site?.link && (
        <a href={links && links.site.link}>
          <p className="text-md p-2 mb-4 rounded  shadow-md text-grey-900 font-bold border transition-colors hover:border-[#3730a3]">
            <HiExternalLink className="inline" />
            {links && links.site.nome}
          </p>
        </a>
      )}

      {links && links.promocional?.link && (
        <a href={links && links.promocional.link}>
          <p className="text-md p-2 mb-4 rounded  shadow-md text-grey-900 font-bold border transition-colors hover:border-[#3730a3]">
            <HiExternalLink className="inline" />
            {links && links.promocional.nome}
          </p>
        </a>
      )}
    </div>
  );
};

export default ContactLinks;

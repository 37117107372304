import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import "./styles.css";
import { FaArrowLeft, FaCheck } from "react-icons/fa";


const CheckoutForm = ({ onBackToInitial }) => {
  const { idCliente, hash } = useParams(); 
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPaymentElementReady, setPaymentElementReady] = useState(false);

  useEffect(() => {
    if (elements) {
      const paymentElement = elements.getElement(PaymentElement);
      paymentElement.on("ready", () => {
        setPaymentElementReady(true);
      });
    }
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const return_url = `${window.location.origin}/${idCliente}/pay/${hash}/payment-status`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url },
    });

    if (error) {
      setErrorMessage(error.message);
    } 
  };

  return (
    <>
    <div className="creditCard-container">

      {isPaymentElementReady && (
        <div className="creditCard-form-header">
          <p>
            Informe abaixo os dados do cartão de crédito correspondente ao
            titular do endereço.
          </p>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        {isPaymentElementReady && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              type="button"
              className="back-button"
              onClick={onBackToInitial}
            >
              <FaArrowLeft /> Voltar
            </button>
            <button type="submit" className="submit-button">
              <FaCheck /> Concluir Pagamento
            </button>
          </div>
        )}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </div>
    </>
  );
};

export default CheckoutForm;

import React, { useState, useEffect, Suspense, lazy } from 'react'
import { toast, Notification, Card, Select, Input, Button, Checkbox, FormItem, FormContainer, Alert, Upload, Radio } from "components/ui";
import { _buscarQuestionarioCliente, _salvarQuestionario } from 'services/AuthService';
import { useNavigate, useParams } from "react-router-dom";
import { FiX } from 'react-icons/fi'
import Anamnese from './formFixo/Anamnese';
import FORMULARIOS_TESTE_PSICOLOGIA from 'constants/tecnicas.constant';
import TecnicasPsicologia from './psicologia/TecnicasPsicologia';
import { HiCloudUpload } from 'react-icons/hi';
import { _fbUploadFile2 } from 'services/FirebaseService';

const CampoPersonalizadoExibicao = ({ infoCampo, handler, initDados }) => {
    const [dados, setDados] = useState({})
    const { idFormulario, idCliente } = useParams();
    const [ext, setExt] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (infoCampo.tipo === 'SELETOR_SIMPLES') {
            if (dados[infoCampo.name]) {
                setFieldValue(dados[infoCampo.name].toString());
            }
        }
    }, [dados[infoCampo.name]]);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let _dados = { ...dados }
        _dados[infoCampo.name] = value;
        setDados(_dados);
        handler(infoCampo.name, value);
    }

    function formValue(key, value) {
        const _dados = { ...dados };
        _dados[key] = value;
        setDados(_dados);
        handler(key, value);
    }

    useEffect(() => {
        if (initDados) {
            setDados(initDados);
        }
    }, [initDados]);


    const Components = {
        TITULO: (
            <i>{infoCampo.descricao}</i>
        ),
        SIMPLES: (
            <Input
                type="text"
                maxLength={600}
                placeholder="Max 600 caracteres"
                className="disabled:border-b-1 border-dotted"
                onChange={handleInputChange}
            />
        ),
        PARAGRAFO: (
            <Input
                type="text"
                textArea
                maxLength={5000}
                placeholder="Max 5000 caracteres"
                className="disabled:border-b-1 border-dotted"
                onChange={handleInputChange}
            />
        ),
        NUMERO: (
            <Input
                type="number"
                min={infoCampo.respostaMinimo}
                max={infoCampo.respostaMaximo}
                onChange={handleInputChange}
            />

        ),
        SELETOR_SIMPLES: (
            <>
                {infoCampo?.options?.map((item, i) => {
                    return (
                        <Radio
                            key={i}
                            className="my-1"
                            name={infoCampo.name}
                            onClick={() => formValue(infoCampo.name, item.value)}
                        >
                            {item.label}
                        </Radio>

                    );
                })}
            </>
        ),
        SELETOR_MULTI: (
            <div className="flex flex-col">
                <Checkbox.Group
                    vertical
                    onChange={(e) => formValue(infoCampo.name, e)}
                >
                    {infoCampo?.options?.map((item, i) => {
                        return (
                            <Checkbox key={i} value={item.value}>
                                {item.label}
                            </Checkbox>
                        );
                    })}
                </Checkbox.Group>
            </div>
        ),
        ARQUIVO: (
            <div className="flex flex-col gap-2">
                {selectedFileName && (
                    <i>{selectedFileName}</i>
                )}
                <Upload
                    showList={false}
                    multiple={false}
                    onChange={async (files) => {
                        setLoading(true);
                        const getFile = files[0];
                        setSelectedFileName(getFile.name);
                        const file = new File([getFile], getFile.name, { type: getFile.type });

                        const filename = `/form/${idFormulario}/${getFile.name}`;

                        await _fbUploadFile2(file, filename, ({ urlFile }) => {
                            formValue(infoCampo.name, urlFile);
                            setLoading(false);
                            toast.push(
                                <Notification
                                    title="Upload executado com sucesso"
                                    type="success"
                                />, {
                                placement: 'top-center',
                            },
                            );
                        }, true);
                    }}
                >
                    <Button className="flex items-center gap-2" variant="solid" loading={loading} disabled={loading}>
                        <HiCloudUpload />
                        Upload de arquivo
                    </Button>
                </Upload>
                <small>Max: 10mb</small>
            </div>
        ),
    };

    useEffect(() => {
        // const fileUrl = dados[infoCampo.name]?.split(/[#?]/)[0]?.split('.').pop().trim();
        if (typeof dados[infoCampo.name] === 'string') {
            if (dados[infoCampo.name].includes('https') || dados[infoCampo.name].includes('http')) {
                setExt(dados[infoCampo.name]?.split(/[#?]/)[0]?.split('.').pop().trim());
            }
        }
    }, [dados[infoCampo.name]]);


    if (infoCampo.tipo === 'TITULO') {
        return (
            <div className="flex flex-col">
                <h5 className="text-indigo-800 mt-2">
                    {infoCampo.pergunta}
                </h5>

                {Components[infoCampo.tipo]}
            </div>
        );
    }

    return (
        <Card>
            <div className="flex flex-col">
                <label>{infoCampo.pergunta}</label>
                {Components[infoCampo.tipo]}
            </div>
        </Card>
    )
}

const QuestionarioCliente = () => {
    const [formularioData, setFormularioData] = useState()
    const [dados, setDados] = useState({})
    const [fim, setFim] = useState(false)
    let { idFormulario, idCliente } = useParams();

    const salvarFormularioClick = async () => {
        try {
            await _salvarQuestionario({
                resultado: JSON.stringify(dados),
                id: idFormulario
            });
            setFim(true);
        }
        catch (_) {
            console.log(_)
            toast.push(<Notification title={'Não foi possível salvar as informações do formulário!'} type="warning" />, {
                placement: 'top-center',
            })
        }

    }

    const setDadosFromVar = (name, value) => {
        setDados({
            ...dados,
            [name]: value
        });
    }

    const buscarInfoFormulario = async () => {
        try {
            const response = await _buscarQuestionarioCliente(idFormulario);
            response.data.design = JSON.parse(response.data.design);
            setFormularioData(response.data);
        }
        catch (err) {
            setFim(true);
            console.log(err)
        }
    }

    useEffect(() => {
        buscarInfoFormulario();
    }, []);

    return (
        <>
            <div style={{ backgroundImage: "url(img/others/ils_12.png)" }}
                className="app-layout-blank flex flex-col justify-center items-center min-h-screen background-image-login">
                <div className="max-w-lg w-full bg-white py-4 px-8 rounded-lg  m-4">
                    {
                        formularioData !== undefined && fim &&
                        <div className="flex flex-col justify-center">
                            <p>Este formulário foi respondido com sucesso. Obrigado!</p>
                            <br />
                            <p>Notificaremos o profissional que lhe atende.</p>
                        </div>
                    }

                    {
                        formularioData !== undefined && !fim && formularioData.id > 10 &&
                        <>
                            <h1>{formularioData?.nome} </h1>

                            <div className="flex flex-col gap-2">
                                {
                                    formularioData?.design.map((campoItem, idx) => (
                                        <CampoPersonalizadoExibicao
                                            key={`chv_${campoItem.name}`}
                                            infoCampo={campoItem}
                                            initDados={dados}
                                            handler={setDadosFromVar} />
                                    ))
                                }
                            </div>

                            <div className="flex my-2 gap-2">
                                <Button variant="solid" onClick={salvarFormularioClick} type="button">Salvar</Button>
                            </div>
                        </>
                    }

                    {
                        formularioData !== undefined && !fim && formularioData.id === 1 &&
                        <Anamnese acesso={1} idFormulario={idFormulario} />
                    }

                    {
                        formularioData !== undefined && !fim && formularioData.id <= 10 && formularioData.id !== 1 &&
                        <TecnicasPsicologia design={formularioData.design} idFormulario={idFormulario} idCliente={idCliente} />
                    }

                    {
                        formularioData === undefined && !fim &&
                        <div className="flex justify-center">
                            <span>Estamos buscando as informações do questionário... por favor aguarde.</span>
                        </div>
                    }

                    {
                        formularioData === undefined && fim &&
                        <div className="flex justify-center">
                            <span>Este formulário não está mais disponível para ser respondido. Por favor, entre em contato com o seu profissional.</span>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default QuestionarioCliente;
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { _reagendarAgenda, _cancelarAgenda } from 'services/AuthService';
import { Button, Card } from 'components/ui'

const ReagendarAgenda = () => {
    const navigate = useNavigate()
    let { uuidAgenda } = useParams();
    const [step, setStep] = useState('INICIO');

    const reagendar = async () => {
        try {
            const response = await _reagendarAgenda(uuidAgenda);
            setStep("REAGENDAR_SUCESSO");
            navigate(`/${response.data.urlAgenda}`)
        }
        catch (err) {
            err.response.status === 409 ? setStep('REAGENDAR_FALHA_TEMPO') : setStep('REAGENDAR_FALHA');
        }
    }

    const cancelar = async () => {
        try {
            const response = await _cancelarAgenda(uuidAgenda);
            setStep('CANCELAR_SUCESSO')
        }
        catch (err) {
            err.response.status === 409 ? setStep('CANCELAR_FALHA_TEMPO') : setStep('CANCELAR_FALHA');
        }
    }

    const FooterButtons = () => {
        return (
            <div className="flex justify-end">
                <Button variant="solid" onClick={reagendar} className="mr-2" type="button">Reagendar</Button>
                <Button variant="twoTone" color="red" onClick={cancelar} type="button">Cancelar</Button>
            </div>
        )
    }

    const Inicio = () => {
        if (step === 'INICIO')
            return (
                <Card className="m-4" footer={<FooterButtons />}>
                    <h5 className="font-semi-bold mb-4">Corpora</h5>
                    <h6 className="font-light">Você deseja <b>reagendar</b> o atendimento ou <b>cancelar</b> a agenda?</h6>
                </Card>
            )
    }

    const Reagendar = () => {
        if (step === 'REAGENDAR_FALHA')
            return (
                <Card className="m-4">
                    <h5 className="font-semi-bold mb-4">Corpora</h5>
                    <h6 className="font-light">Desculpe. Não foi possível proceder com o reagendamento do atendimento.</h6>
                </Card>
            )
        else if (step === 'REAGENDAR_FALHA_TEMPO')
            return (
                <Card className="m-4">
                    <h5 className="font-semi-bold mb-4">Reagendamento Não Realizado</h5>
                    <h6 className="font-light">Desculpe. Neste momento para proceder com o reagendamento você deve entrar em contato direto com o profissional.</h6>
                </Card>
            )
        else if (step === 'REAGENDAR_SUCESSO')
            return (
                <Card className="m-4">
                    <h6 className="font-light">Seguindo para agenda do profissional...</h6>
                </Card>
            )

    }

    const Cancelar = () => {
        if (step === 'CANCELAR_FALHA')
            return (
                <Card className="m-4">
                    <h5 className="font-semi-bold mb-4">Corpora</h5>
                    <h6 className="font-light">Desculpe. Não foi possível cancelar o atendimento.</h6>
                </Card>
            )
        else if (step === 'CANCELAR_FALHA_TEMPO')
            return (
                <Card className="m-4">
                    <h5 className="font-semi-bold mb-4">Cancelamento Não Realizado</h5>
                    <h6 className="font-light">Desculpe. Nesse momento essa agenda só pode ser cancelada entrando em contato direto com o profissional.</h6>
                </Card>
            )
        else if (step === 'CANCELAR_SUCESSO')
            return (
                <Card className="m-4">
                    <h5 className="font-semi-bold mb-4">Cancelamento Realizado</h5>
                    <h6 className="font-light">O cancelamento da agenda foi realizado com sucesso.</h6>
                </Card>
            )
    }

    return (
        <div>
            <Inicio />
            <Reagendar />
            <Cancelar />
        </div>
    )

}

export default ReagendarAgenda;
export const firebaseConfigTeste = {

    apiKey: "AIzaSyA_mCp0yjagTHQXvTy6jzg6Pk83kN2Spk0",
    authDomain: "terapiabd-testes.firebaseapp.com",
    databaseURL: "https://terapiabd-testes-default-rtdb.firebaseio.com",
    projectId: "terapiabd-testes",
    storageBucket: "terapiabd-testes.appspot.com",
    messagingSenderId: "1053100895381",
    appId: "1:1053100895381:web:4890d8d8344e9e928bff9c"
};

export const firebaseConfig = {
    apiKey: "AIzaSyAaSoOsyxEYeTNe2LmfVVQ3Wuch5pFL-L0",
    authDomain: "terapiabd-34758.firebaseapp.com",
    databaseURL: "https://terapiabd-34758-default-rtdb.firebaseio.com",
    projectId: "terapiabd-34758",
    storageBucket: "terapiabd-34758.appspot.com",
    messagingSenderId: "307911750322",
    appId: "1:307911750322:web:1907ebe564567387218fab",
    measurementId: "G-ZXGT5B0FPW"
};

import React, { useEffect, useState } from 'react';
import { _confirmarAgenda, _buscarAgendaByUuid } from 'services/AuthService';
import { useParams } from "react-router-dom";
import { Button, Card } from 'components/ui'
import { FaCheck } from "react-icons/fa";

const ConfirmacaoAgenda = () => {
    let { uuidAgenda } = useParams();
    const [step, setStep] = useState('INICIO');
    const [dados, setDados] = useState({});

    const confirmar = async () => {
        try {
            await _confirmarAgenda(uuidAgenda);
            setStep('CONFIRMADO');
        }
        catch (err) {
            setStep('ERRO');
        }
    }

    const buscarAgenda = async () => {
        try {
            const response = await _buscarAgendaByUuid(uuidAgenda);
            setDados(response.data);
            setStep(response.data.status);
        }
        catch (err) {
            setStep('ERRO');
        }
    }

    useEffect(() => {
        buscarAgenda();
    }, []);

    switch (step) {
        case 'PREVISTO':
            return (
                <div className="flex flex-col flex-wrap gap-2 m-8">
                    <h3 className="font-light mb-4">Confirmação de Agenda</h3>
                    <h6 className="font-light">Você deseja confirmar a agenda para o dia <span className="font-bold text-indigo-900">{dados.dataInicio}hs</span>?</h6>
                    <Button variant="solid" onClick={confirmar} className="mt-4">Quero Confirmar</Button>

                    <PoweredCorpora/>
                </div>
            )
        case 'CONFIRMADO':
            return (
                <div className="flex flex-col flex-wrap gap-2 m-8">
                    <div className="flex text-center justify-center">
                        <FaCheck className="text-green-700" style={{ fontSize: "80px" }} />
                    </div>
                    <h4 className="font-semi-bold mb-8 text-center text-green-700">Agenda Confirmada</h4>
                    <h6 className="font-light mb-8">A agenda para o dia <span className="font-bold text-indigo-900">{dados.dataInicio}hs</span> foi confirmada com sucesso.</h6>
                    <h6 className="font-light text-sm">Este link foi invalidado, não podendo mais ser utilizado.</h6>

                    <PoweredCorpora/>
                </div>
            )
        case 'ERRO':
            return (
                <div className="m-4">
                    <h5 className="font-semi-bold mb-4">Corpora</h5>
                    <h6 className="font-light">O link não está mais válido. Se você realizou algum processo de confirmação ou cancelamento e deseja voltar atrás, pedimos que entre em contato direto com o profissional.</h6>
                </div>
            )
    }
}

const PoweredCorpora = () => {
    return (
        <div className="flex flex-row self-center my-8">
            <span>Powered by </span>
            <a
                href="https://www.usecorpora.com.br"
                className="text-indigo-800 font-bold"
            >
                <img src="/img/logo/logo-light-full.png" width="120" />
            </a>
        </div>
    )
}   

export default ConfirmacaoAgenda;;
import ApiService from "./ApiService";

export const _buscarPacientePorId = (idPaciente) => {
  return ApiService.fetchData({
    url: `/rest/init/cliente/${idPaciente}`,
    method: "get",
  });
};

export const _atualizarCliente = (data) => {
  return ApiService.fetchData({
    url: `/rest/init/cliente/atualizar`,
    method: "put",
    data,
  });
};

export async function _salvarNovoPaciente(urlAgenda, uuidProfissional, dados) {
  return ApiService.fetchData({
    url: `/rest/init/cliente/cadastro/${urlAgenda}/${uuidProfissional}`,
    method: "post",
    data: dados,
  });
}

export async function _salvarNovoContato(dados) {
  return ApiService.fetchData({
    url: `/rest/init/cliente/contato`,
    method: "post",
    data: dados,
  });
}

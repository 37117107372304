import React from "react";
import "./styles.css";

const PaymentNotFound = ({ message }) => {
  return (
    <div className="invalid-payment-container">
      <h2>{message}</h2>
      {/* <p>{message}</p> */}
    </div>
  );
};

export default PaymentNotFound;

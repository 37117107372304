import { USUARIO_SUB_KEY, ANALISE_KEY, CLIENTE_KEY, firebaseConfig, USUARIO_KEY } from "../constants/firebase.constant";
import { initializeApp } from "firebase/app";
import { getDatabase, set, get, push, update } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import {
    uploadBytesResumable,
    getDownloadURL,
    getStorage,
    ref
} from 'firebase/storage';

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);
const auth = getAuth();
const storage = getStorage(app);


export const writeUserData = (userId, name, email, imageUrl) => {
    const db = getDatabase();
    set(ref(db, 'users/' + userId), {
        username: name,
        email: email,
        profile_picture: imageUrl
    });
}

/*export const starCountRef = ref(db, 'posts/' + postId + '/starCount');
onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    updateStarCount(postElement, data);
});*/

// export const gravarNovoUsuario = (usuario, uid) => {
//     const dbRef = ref(database, `${USUARIO_KEY}/${uid}/${USUARIO_SUB_KEY}`);
//     set(dbRef, usuario);
// }

// export const logarUsuario = async (usuario) => {
//     return signInWithEmailAndPassword(auth, usuario.userName, usuario.password)
// }

// export const receberEmailEsqueciSenha = async (email) => {
//     return sendPasswordResetEmail(auth, email)
// }

// export const signOutUser = async () => {
//     return signOut(auth)
// }

// export const criarUsuario = async (usuario) => {
//     return createUserWithEmailAndPassword(auth, usuario.email, usuario.password)
// }

// export const salvarNovoCliente = (cliente, uid) => {
//     if (uid != null) {
//         const dbRef = ref(database, `${USUARIO_KEY}/${uid}/${CLIENTE_KEY}`);
//         push(dbRef, cliente);
//     }
// }

// export const atualizarCliente = (cliente, clienteId, uid) => {
//     if (uid != null) {
//         const dbRef = ref(database, `${USUARIO_KEY}/${uid}/${CLIENTE_KEY}/${clienteId}`);
//         update(dbRef, cliente);
//     }
// }

// export const buscarClientesUsuario = async (uid) => {
//     const dbRef = ref(database, `${USUARIO_KEY}/${uid}/${CLIENTE_KEY}`);
//     let data = [];
//     return get(dbRef);
// }

// export const buscarDadosUsuario = async (uid) => {
//     const dbRef = ref(database, `${USUARIO_KEY}/${uid}/${USUARIO_SUB_KEY}`);
//     return get(dbRef);
// }

// export const salvarAnaliseCliente = (uid, clienteId, dadosAnalise, dataHora) => {
//     const dbRef = ref(database, `${USUARIO_KEY}/${uid}/${CLIENTE_KEY}/${clienteId}/${ANALISE_KEY}`);
//     set(dbRef, { game: JSON.stringify(dadosAnalise), dataHora: dataHora.getTime() });
// }

export const _fbUploadFile2 = async (file, filename, afterUpload) => {
    const storageRef = ref(storage, filename);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed',
        (snapshot) => {

        },
        (error) => {
            // Handle unsuccessful uploads
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                afterUpload({ urlFile: downloadURL });
            });
        });
};

export const _fbGetFile = async (fileName) => {
    const storageRef = ref(storage, fileName);
    return getDownloadURL(storageRef).then((downloadUrl) => downloadUrl);
};


import React from 'react';

export const TRG = { id: 1, nome: "TRG", link: "/formFixo", component: React.lazy(() => import('views/tecnicas/formFixo/Anamnese')) };
export const CBC = { id: 2, nome: "CHILD BEHAVIOR CHECKLIST FOR AGES 6-18", link: "/psicologia", component: React.lazy(() => import('views/tecnicas/psicologia/TecnicasPsicologia')) };
export const ATA = { id: 3, nome: "Escala de Avaliação de Traços Autísticos - ATA", link: "/psicologia", component: React.lazy(() => import('views/tecnicas/psicologia/TecnicasPsicologia')) };
export const SPIN = { id: 4, nome: "Inventário de Fobia Social - SPIN", link: "/psicologia", component: React.lazy(() => import('views/tecnicas/psicologia/TecnicasPsicologia')) };
export const PFA = { id: 5, nome: "Perfil Sensorial de Adultos - PFA", link: "/psicologia", component: React.lazy(() => import('views/tecnicas/psicologia/TecnicasPsicologia')) };
export const MASC = { id: 6, nome: "Protocolo de Ansiedade - MASC", link: "/psicologia", component: React.lazy(() => import('views/tecnicas/psicologia/TecnicasPsicologia')) };
export const SCARED = { id: 7, nome: "Escala de Avaliação de Ansiedade - SCARED", link: "/psicologia", component: React.lazy(() => import('views/tecnicas/psicologia/TecnicasPsicologia')) };

export const FORMULARIOS_TESTE_PSICOLOGIA = [
    TRG,
    CBC,
    ATA,
    SPIN,
    PFA,
    MASC,
    SCARED
]


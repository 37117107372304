import React, { useState } from "react";
import { Card, Button } from "components/ui";
import ConfirmationDialog from "./components/ConfirmationDialog";

const ServicoSelection = ({
  servicosLocalidades,
  localidadeEscolhida,
  escolherServicoClick,
  setStep,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  if (!localidadeEscolhida) {
    return null;
  }

  const handleServiceClick = (servico) => {
    setSelectedService(servico);
    if (servico.cobrarAntecipado) {
      setIsDialogOpen(true);
    } else {
      escolherServicoClick(servico);
      setStep(2);
    }
  };

  const handleDialogConfirm = () => {
    setIsDialogOpen(false);
    escolherServicoClick(selectedService);
    setStep(2);
  };

  return (
    <div className="flex flex-col self-center">
      <p className="text-indigo-800 font-bold">Escolha um Serviço</p>

      <div className="gap-2 flex-col flex">
        {Object.keys(servicosLocalidades)
          .sort()
          .flatMap((key) => servicosLocalidades[key])
          .filter((item) => item.localidade.id === localidadeEscolhida.id)
          .map((item) => (
            <Card
              key={item.servico.id}
              className="cursor-pointer border transition-colors hover:border-[#3730a3] my-4"
              onClick={() => handleServiceClick(item.servico)}
            >
              <p className="font-bold text-lg">{item.servico.nome}</p>
              <p className="text-base">Duração: {item.servico.duracao} min</p>
              {item.servico.mostrarAgenda === "ATIVO" && (
                <p className="text-base">
                  Valor:{" "}
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(
                    item.servico.cobrarAntecipado
                      ? item.servico.valor * 1.05 // Aplica 5% se cobrarAntecipado for true
                      : item.servico.valor // Caso contrário, exibe o valor original
                  )}
                </p>
              )}

              {item.servico.mostrarAgenda === "INATIVO" && (
                <p>Valor a combinar</p>
              )}

              {item.servico.cobrarAntecipado && (
                <p className="text-indigo-800 font-bold">
                  Pagamento ao marcar consulta
                </p>
              )}
            </Card>
          ))}
      </div>
      <Button
        className="my-4 cursor-pointer border transition-colors hover:border-[#3730a3]"
        onClick={() => setStep(0)}
      >
        Voltar
      </Button>

      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleDialogConfirm}
      />
    </div>
  );
};

export default ServicoSelection;

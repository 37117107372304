import React from "react";
import { Button } from "components/ui";
import dayjs from "dayjs";

const HorarioSelection = ({
  data,
  dadosTerapeuta,
  servicoEscolhido,
  localidadeEscolhida,
  agendaSelecionadaId,
  setAgendaSelecionadaId,
  setHoraUsuario,
  setHoraProfissional,
  setStep,
  timezonePaciente,
  dados,
}) => {

  const MontarHorarios = () => {
    const timezone = dadosTerapeuta.timezone;
    const horariosDisponiveis = [];


    dados.forEach((agenda) => {
      if (
        agenda.servicoId === servicoEscolhido?.id &&
        agenda.localidadeServicoId === localidadeEscolhida?.id
      ) {
        agenda.horarios.forEach((horario) => {
          if (horario.estado !== "INDISPONIVEL") {
            const horarioConvertido = dayjs
              .tz(`${agenda.dia} ${horario.h}`, "YYYY-MM-DD HH:mm", timezone)
              .tz(timezonePaciente.current);
            const diaConvertido = horarioConvertido.format("YYYY-MM-DD");
            const horaConvertida = horarioConvertido.format("HH:mm");
            horariosDisponiveis.push({
              dia: diaConvertido,
              h: horaConvertida,
              originalHorario: horario.h,
              id: agenda.id,
            });
          }
        });
      }
    });

    const horariosDoDia = horariosDisponiveis
      .filter(
        (horario) =>
          horario.dia === data &&
          dayjs(`${horario.dia} ${horario.h}`).isAfter(dayjs())
      )
      .sort((a, b) => (a.h > b.h ? 1 : -1));


    return (
      <>
        {horariosDoDia.map((horario) => (
          <Button
            key={horario.h}
            size="lg"
            onClick={() => {
              setHoraUsuario(horario.h);
              setHoraProfissional(horario.originalHorario);
              setAgendaSelecionadaId(horario.id);
              setStep(4);
            }}
            variant="twoTone"
          >
            {horario.h}
          </Button>
        ))}
      </>
    );
  };
  return (
    <div className="flex flex-col gap-4 self-center w-[300px]">
      <h3 className="text-indigo-800 font-bold">
        Escolha um Horário{" "}
        <span className="text-black text-sm">
          {dayjs(data).format("DD/MM/YYYY")}
        </span>
      </h3>

      <div className="flex flex-col overflow-auto gap-4 self-center w-[300px] h-[300px]">
        {MontarHorarios()}
      </div>

      <Button onClick={() => setStep(2)} className="my-4 cursor-pointer border transition-colors hover:border-[#3730a3]">
        Voltar
      </Button>
    </div>
  );
};

export default HorarioSelection;

export function formatWhatsNumber(numero) {
    return numero?.replace('+', '').replace(/\s/g, '').replace(/\(/g, '').replace(/\)/g, '')
        .replace(/\-/g, '');
}

export function formatNumber(v) {
    if (!v) return '';
    let r = v.replace('+55').replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
        r = r.replace(/^(\d*)/, '($1');
    }
    return r;
}

export function formatCPF(cpf) {
    if (!cpf) return '';
    let r = cpf.replace(/\D/g, ''); 
    
    r = r.replace(/(\d{3})(\d)/, '$1.$2');
    r = r.replace(/(\d{3})(\d)/, '$1.$2');
    r = r.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    
    return r;
}
export const validateForm = (values) => {
  const errors = {};

  if (!values.nome) {
    errors.nome = "O nome é obrigatório";
  }

  if (!values.telefone) {
    errors.telefone = "O telefone é obrigatório";
  }

  if (!values.relacao) {
    errors.relacao = "A relação é obrigatória";
  }

  return errors;
};

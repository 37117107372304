import { Notification, toast } from "components/ui";

const buscarEnderecoPorCEP = async (cep) => {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    if (response.ok) {
      const data = await response.json();
      return {
        endereco: data.logradouro,
        cidade: data.localidade,
        estado: data.uf,
        bairro: data.bairro,
      };
    }
    toast.push(
      <Notification
        title="CEP não encontrado. Verifique o CEP e tente novamente"
        type="warning"
      />,
      {
        placement: "top-center",
      }
    );
  } catch (error) {
    toast.push(
      <Notification
        title="CEP não encontrado. Verifique o CEP e tente novamente"
        type="warning"
      />,
      {
        placement: "top-center",
      }
    );
  }
};

export default buscarEnderecoPorCEP;

import React, { useState } from "react";
import { FiCreditCard, FiShoppingCart } from "react-icons/fi";
import { ImBarcode } from "react-icons/im";
import { FaPix } from "react-icons/fa6";
import pixImage from "assets/images/pix-106.png";
import "./styles.css";

export const PaymentInfo = ({ onProceedToPayment, clientData }) => {
  const [selectedPayment, setSelectedPayment] = useState(null);

  const handlePaymentSelection = (paymentType) => {
    setSelectedPayment(paymentType);
  };

  const handleProceedToPayment = () => {
    if (selectedPayment) {
      onProceedToPayment(selectedPayment);
    }
  };

  const paymentOptions = clientData.pagamento.formaPagamentoLiberada;

  return (
    <div className="payment-methods">
      <h4 className="text-indigo-800">Selecione a forma de pagamento</h4>
      <div className="payment-container">
        {paymentOptions.includes("CARTAO_CREDITO") && (
          <div
            className={`payment-option ${selectedPayment === "CARTAO_CREDITO" ? "selected" : ""}`}
            onClick={() => handlePaymentSelection("CARTAO_CREDITO")}
          >
            <div className="payment-content">
              <FiCreditCard className="payment-icon" />
              <span>Cartão de Crédito</span>
            </div>
          </div>
        )}
        {paymentOptions.includes("BOLETO") && (
          <div
            className={`payment-option ${selectedPayment === "BOLETO" ? "selected" : ""}`}
            onClick={() => handlePaymentSelection("BOLETO")}
          >
            <div className="payment-content">
              <ImBarcode className="payment-icon" />
              <span>Boleto</span>
            </div>
          </div>
        )}
        {paymentOptions.includes("PIX") && (
          <div
            className={`payment-option ${selectedPayment === "PIX" ? "selected" : ""}`}
            onClick={() => handlePaymentSelection("PIX")}
            
          >
            <div className="payment-content">
              <FaPix alt="Pix" className="pix-icon" />
              <span>Pix</span>
            </div>
          </div>
        )}
      </div>
      <button
        className={`pay-button ${selectedPayment ? "active" : ""}`}
        disabled={!selectedPayment}
        onClick={handleProceedToPayment}
      >
        <FiShoppingCart className="cart-icon" /> Ir para pagamento
      </button>
    </div>
  );
};

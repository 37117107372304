import { useState } from "react";
import { _salvarAgendaPublica, _buscarClienteTerapeuta } from "services/AgendaPublicaService";
import { toast } from "react-toastify";

const useClientData = (idCliente,dadosTerapeuta, agendaSelecionadaId, horaProfissional, timezonePaciente, setStep, servicoEscolhido, setReservaRedirectUrl) => {
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [motivo, setMotivo] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [sending, setSending] = useState(false);
  const [clienteConhecido, setClienteConhecido] = useState(false);

  const checarSeClienteTerapeutaClick = async () => {
    if (telefone.replaceAll("_", "").length < 5) {
      toast.warning("Por favor informe um telefone válido para agendamento");
      return;
    }

    if (motivo.length < 5) {
      toast.warning("Por favor nos informe o motivo do agendamento");
      return;
    }

    try {
      const resp = await _buscarClienteTerapeuta(dadosTerapeuta.id, telefone);
      setClienteConhecido(true);
      agendarClick(true);
    } catch (error) {
     console.log("error", error)
    }
  };

  const agendarClick = async (conhecido = false) => {
    setSending(true);

    console.log(conhecido)

    if (!conhecido && (nome === undefined || nome.trim() === "" || telefone === "")) {
    //if ((nome === undefined || nome.trim() === "" || telefone === "")) {
      toast.warning("Por favor informe seu nome e telefone");
      setSending(false);
      return;
    }

    const dadosSalvar = {
      nome,
      cpf: cpf.replaceAll("_", ""),
      telefone,
      email,
      motivo,
      dataNascimento: dataNascimento.replaceAll("_", ""),
      agendaId: agendaSelecionadaId,
      horario: horaProfissional,
      timezone: timezonePaciente.current,
    };

    try {
      const response = await _salvarAgendaPublica(idCliente, dadosSalvar);
      const redirectUrl = response.data.redirectUrl;
      
      console.log("servicoEscolhido.cobrarAntecipado", servicoEscolhido.cobrarAntecipado)

      if (servicoEscolhido.cobrarAntecipado) {
        setReservaRedirectUrl(redirectUrl);
        setStep(7); 
      } else {
        toast.success("Agenda salva com sucesso!");
        setStep(6);
      }
    } catch (error) {
      handleAgendamentoError(error);
    } finally {
      setSending(false);
    }
  };

  const handleAgendamentoError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          toast.error("Requisição inválida.");
          break;
        case 403:
          toast.error("O agendamento está fora do período permitido.");
          break;
        case 404:
          toast.warning("Recurso não encontrado.");
          break;
        case 409:
          toast.warning("Conflito de horário, escolha outro horário.");
          break;
        case 500:
          toast.error("Erro no servidor.");
          break;
        default:
          toast.error("Erro inesperado.");
      }
    } else {
      toast.error("Erro na comunicação com o servidor.");
    }
  };

  return {
    nome,
    setNome,
    cpf,
    setCpf,
    telefone,
    setTelefone,
    email,
    setEmail,
    motivo,
    setMotivo,
    dataNascimento,
    setDataNascimento,
    sending,
    setSending,
    clienteConhecido,
    setClienteConhecido,
    checarSeClienteTerapeutaClick,
    agendarClick,
  };
};

export default useClientData;

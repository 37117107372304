import ApiService from "./ApiService";

export const _buscarPagamento = (user, uuid) => {
  return ApiService.fetchData({
    url: `/rest/init/pagamento/${user}/${uuid}`,
    method: "get",
  });
};


export const __buscarPagamentoForma = (user, uuid, payment) => {
  return ApiService.fetchData({
    url: `/rest/init/pagamento/checkout/${user}/${uuid}/${payment}`,
    method: "post",
  });
}
import ApiService from './ApiService'
import BaseService from './BaseService'

export const _salvarAgendaPublica = (idTerapeuta, dados) => {
    return ApiService.fetchData({
        url: `/rest/init/agenda/${idTerapeuta}`,
        method: 'post',
        data: dados
    })
}

export const _buscarAgendaPublica = (idTerapeuta) => {
    return ApiService.fetchData({
        url: `/rest/init/agenda/${idTerapeuta}`,
        method: 'get'
    })
}

export const _buscarClienteTerapeuta = (idTerapeuta, clienteCpf) => {
    return ApiService.fetchData({
        url: `/rest/init/terapeuta/${idTerapeuta}/cliente/${clienteCpf}`,
        method: 'get'
    })
}

// export async function _buscarFotoPerfilInstaArquivo(instaId) {
    
//     return BaseService.get(`https://www.instagram.com/${instaId}/?__a=1&__d=1`);
// }
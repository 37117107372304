import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useParams } from "react-router-dom";
import { SuccessPage } from '../components/ScreenMessages/success';
import { ErrorPage } from '../components/ScreenMessages/errorPage';
import ProcessingPayment from '../components/ScreenMessages/processingPayment';
import LoadingPaymentInfo from '../components/ScreenMessages/loadingPayment';
import { _buscarPagamento } from 'services/CheckoutService';

const PaymentStatus = () => {
  const { idCliente, hash } = useParams(); 
  const stripe = useStripe();
  const [status, setStatus] = useState('loading');
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    _buscarPagamento(idCliente, hash)
      .then((response) => {
        setClientData(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar o clientSecret:", error);
      });
  }, []);


  useEffect(() => {
    if (!stripe) {
      return;
    }


    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      setStatus('error');
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setStatus('success');
          break;
        case 'processing':
          setStatus('processing');
          break;
        case 'requires_payment_method':
          setStatus('error');
          break;
        default:
          setStatus('error');
          break;
      }
    }).catch(() => {
      setStatus('error'); 
    });
  }, [stripe]);

  switch (status) {
    case 'success':
      return <SuccessPage clientData={clientData} />;
    case 'error':
      return <ErrorPage />;
    case 'processing':
      return <ProcessingPayment />;
    default:
      return <LoadingPaymentInfo />;
  }
};

export default PaymentStatus;

import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import cliente from './clienteSlice'

const reducer = combineReducers({
    common,
    cliente
})

export default reducer

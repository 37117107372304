import React, { useState, useEffect, Suspense, lazy } from 'react'
import { toast, Notification, Table, Input, Button, Checkbox, FormItem, FormContainer, Alert } from "components/ui";
import { useNavigate, useParams } from "react-router-dom";
import { FiEdit, FiInstagram, FiCheck, FiX, FiAtSign } from 'react-icons/fi'
import { _salvarQuestionarioTecnica } from 'services/AuthService';
import { TRG } from 'constants/tecnicas.constant'

const perguntasBd = [
    { name: "nome", pergunta: "Nome completo", tipo: "SIMPLES" },
    { name: "dataNasc", pergunta: "Data de Nascimento", tipo: "SIMPLES" },
    { name: "endereco", pergunta: "Endereço completo", tipo: "SIMPLES" },
    { name: "telefone", pergunta: "Telefone", tipo: "SIMPLES" },
    { name: "nomeTelefone", pergunta: "Nome e Telefone de pessoa próxima", tipo: "SIMPLES" },
    { name: "cidadeUf", pergunta: "Cidade/UF", tipo: "SIMPLES" },
    { name: "escolaridade", pergunta: "Escolaridade", tipo: "SIMPLES" },
    { name: "religiao", pergunta: "Religião?", tipo: "SIMPLES" },
    { name: "profissao", pergunta: "Profissão", tipo: "SIMPLES" },
    { name: "sofrimento", pergunta: "Qual seu maior sofrimento hoje? Descreva tudo que te causa dor, sofrimento, tristeza e frustração na sua vida.", tipo: "SIMPLES" },
    { name: "estadoCivil", pergunta: "Estado Civil", tipo: "SIMPLES" },
    { name: "feliz", pergunta: "Você é feliz assim?", tipo: "SIMPLES" },
    { name: "divorciado", pergunta: "Se é divorciada/o, por qual motivo se separou e como se sente?", tipo: "SIMPLES" },
    { name: "filhos", pergunta: "Número de filhos", tipo: "SIMPLES" },
    { name: "filhosRelacionamento", pergunta: "Como é o seu relacionamento com seus filhos?", tipo: "SIMPLES" },
    { name: "parceiroRelacionamento", pergunta: "Como você se sente em seu relacionamento com seu/sua parceiro/a?", tipo: "SIMPLES" },
    { name: "contextoFamiliar", pergunta: "Como você se sente em sua casa, dentro do contexto familiar?", tipo: "SIMPLES" },
    { name: "relacaoTrabalho", pergunta: "Como você se sente no seu trabalho?", tipo: "SIMPLES" },
    { name: "contextoFamiliar2", pergunta: "Você se sente pertencendo ao Contexto Familiar? Por quê?", tipo: "SIMPLES" },
    { name: "contextoSocial", pergunta: "Você se sente pertencendo ao Contexto Social? Por quê?", tipo: "SIMPLES" },
    { name: "contextoReligioso", pergunta: "Você se sente pertencendo ao Contexto Religioso? Por quê?", tipo: "SIMPLES" },
    { name: "frustracao", pergunta: "Você sente frustração em relação a pais, irmãos, filhos, profissão, cônjuge, identidade sexual, vida sexual, aparência física? Algum destes? Por quê?", tipo: "SIMPLES" },
    { name: "sexualidadeIdade", pergunta: "Iniciou sua sexualidade com que idade?", tipo: "SIMPLES" },
    { name: "primeiraVez", pergunta: "Como foi a sua primeira vez? Normal, boa, traumática? Explique.", tipo: "SIMPLES" },
    { name: "problemaSexo", pergunta: "Tem tido algum problema em relação ao sexo?", tipo: "SIMPLES" },
    { name: "realizaRelacoesSexuais", pergunta: "Atualmente sempre se realiza nas relações sexuais?", tipo: "SIMPLES" },
    { name: "importanteSexo", pergunta: "O sexo pra você é algo importante, sem importância ou muito importante? Explique.", tipo: "SIMPLES" },
    { name: "trauma", pergunta: "Algum trauma? Qual?", tipo: "SIMPLES" },
    { name: "medos", pergunta: "Tem alguma fobia? Qual?", tipo: "SIMPLES" },
    { name: "drogas", pergunta: "Usa drogas? Quais tipos?", tipo: "SIMPLES" },
    { name: "doresCabeca", pergunta: "Tem dores de cabeça? Com que frequência", tipo: "SIMPLES" },
    { name: "insonia", pergunta: "Tem insônia? Com que frequência?", tipo: "SIMPLES" },
    { name: "ideaisSuicida", pergunta: "Tem ideias suicidas? Quais?", tipo: "SIMPLES" },
    { name: "alcoolicas", pergunta: "Usa bebidas alcoólicas? Com que frequência?", tipo: "SIMPLES" },
    { name: "fuma", pergunta: "Fumante?", tipo: "SIMPLES" },
    { name: "gravida", pergunta: "Está grávida? Quantas semanas?", tipo: "SIMPLES" },
    { name: "stress", pergunta: "Qual seu nível de stress? Baixo, médio ou alto?", tipo: "SIMPLES" },
    { name: "medicacao", pergunta: "Atualmente está tomando alguma medicação? Qual?", tipo: "SIMPLES" },
    { name: "consultou", pergunta: "Já consultou algum psicólogo ou psiquiatra? Qual o diagnóstico?", tipo: "SIMPLES" },
    { name: "qtdeAmigos", pergunta: "Qual a quantidades de amigos que você tem?", tipo: "SIMPLES" },
    { name: "passatempo", pergunta: "Qual seu passatempo preferido?", tipo: "SIMPLES" },
    { name: "crencaSobreVc", pergunta: "Qual a principal crença que as pessoas possuem em relação a você que mais se repete?", tipo: "SIMPLES" },
    { name: "eufeliz", pergunta: "Você se considera feliz? Por que?", tipo: "SIMPLES" },
    { name: "mudancaModo", pergunta: "Se você pudesse mudar alguma coisa em você, no seu modo de ser, de agir, ou no seu comportamento atual, o que você mudaria?", tipo: "SIMPLES" },
    { name: "oqueehvida", pergunta: "Defina o que é a vida em apenas uma frase!", tipo: "SIMPLES" },
];


const perguntasBd2 = [
    { name: "pensamentos_2", pergunta: "Quais são os tipos de pensamentos que você costuma alimentar em relação a si mesma (o), de uma maneira geral? Positivos, negativos? Explique.", tipo: "SIMPLES" },
    { name: "aparencia_2", pergunta: "Em relação a sua aparência física? Positivos, negativos? Explique.", tipo: "SIMPLES" },
    { name: "competencia_2", pergunta: "Em relação a sua competência profissional? Positivos, negativos? Explique.", tipo: "SIMPLES" },
    { name: "vidaEmocional_2", pergunta: "Em relação a sua vida emocional? Positivos, negativos? Explique.", tipo: "SIMPLES" },
    { name: "vidaSexual_2", pergunta: "Em relação a sua vida sexual? Positivos, negativos? Explique.", tipo: "SIMPLES" },
    { name: "passado_2", pergunta: "Em relação ao seu passado? Positivos, negativos? Explique.", tipo: "SIMPLES" },
    { name: "futuro_2", pergunta: "Em relação ao seu futuro? Positivos, negativos? Explique.", tipo: "SIMPLES" },
    { name: "visaoSobreVoce_2", pergunta: "Qual sua visão sobre você?", tipo: "SIMPLES" },
];

const perguntasBd3 = [
    { name: "vidaEmocional_3", pergunta: "Você foi criado pelos seus pais?", tipo: "SIMPLES" },
    { name: "relacaoComPais_3", pergunta: "Como é sua relação com seus pais?", tipo: "SIMPLES" },
    { name: "paisAgressivos_3", pergunta: "Seus pais foram agressivos com você? Qual era o mais bravo?", tipo: "SIMPLES" },
    { name: "paisBebidasDrogas_3", pergunta: "Usavam bebidas ou drogas?", tipo: "SIMPLES" },
    { name: "relacionamentoPais_3", pergunta: "Como você descreveria o relacionamento entre seus pais? Por quê?", tipo: "SIMPLES" },
    { name: "aspectosRelacionamento_3", pergunta: "Quais os aspectos deste relacionamento que se assemelham ou se repetem em sua vida hoje?", tipo: "SIMPLES" },
    { name: "caracteristicasNaoRepetir_3", pergunta: "Quais as características deste relacionamento que você se mantém determinada (o) a não repetir?", tipo: "SIMPLES" },
    { name: "crencaRelacionamentos_3", pergunta: "Quanto ao relacionamento de seus pais responda: Qual a crença que você adquiriu em relação a relacionamentos?", tipo: "SIMPLES" },
    { name: "infanciaObrigado_3", pergunta: "Na infância, era obrigado (a) a fazer alguma coisa que lhe desagradava? O que exatamente?", tipo: "SIMPLES" },
    { name: "infanciaMagoa_3", pergunta: "Relate algo que te magoou muito na infância.", tipo: "SIMPLES" },
    { name: "perdasFamiliares_3", pergunta: "Teve perdas familiares ou de amigos? Quem você perdeu?", tipo: "SIMPLES" },
    { name: "tristezaPassado_3", pergunta: "O que faz sentir tristeza ao relembrar do passado?", tipo: "SIMPLES" },
    { name: "medoInfancia_3", pergunta: "Quando criança tinha medo de quê?", tipo: "SIMPLES" },
    { name: "dormiaLuzAcesa_3", pergunta: "Dormia com a luz acesa?", tipo: "SIMPLES" },
    { name: "adolescencia_3", pergunta: "Como foi sua adolescência?", tipo: "SIMPLES" },
    { name: "faseRebeldia_3", pergunta: "Teve fase de rebeldia na adolescência?", tipo: "SIMPLES" },
    { name: "dificuldadeRelacionamentoPais_3", pergunta: "Com qual de seus pais você tinha mais dificuldade de relacionamento?", tipo: "SIMPLES" },
    { name: "filosofiaFamiliaSucesso_3", pergunta: "Qual a filosofia de sua família em relação ao sucesso profissional?", tipo: "SIMPLES" },
    { name: "filosofiaFamiliaDinheiro_3", pergunta: "Ao dinheiro?", tipo: "SIMPLES" },
    { name: "filosofiaFamiliaAmor_3", pergunta: "Ao amor?", tipo: "SIMPLES" },
    { name: "filosofiaFamiliaSexo_3", pergunta: "Ao sexo?", tipo: "SIMPLES" },
    { name: "boaMenina_3", pergunta: "O que era pra você, ser uma boa (bom) menina (o)? Descreva.", tipo: "SIMPLES" },
    { name: "agirParaSerAmado_3", pergunta: "Como você deveria agir para ser amada (o)?", tipo: "SIMPLES" },
    { name: "possuiIrmaos_3", pergunta: "Possui irmãos? Quantos?", tipo: "SIMPLES" },
    { name: "relacaoIrmaos_3", pergunta: "Como é sua relação com eles?", tipo: "SIMPLES" },
    { name: "posicaoIrmaos_3", pergunta: "Você é o filho(a) Mais velho/a, Do meio, Mais novo/a, Filho/a Único/a?", tipo: "SIMPLES" },
    { name: "criancaExtrovertida_3", pergunta: "Você foi uma criança extrovertida?", tipo: "SIMPLES" },
    { name: "difiColegasEscola_3", pergunta: "Havia dificuldades de relacionamentos com os colegas do colégio? Se sim, cite-os.", tipo: "SIMPLES" },
    { name: "medosInfancia_3", pergunta: "Quais eram seus maiores medos na infância?", tipo: "SIMPLES" },
    { name: "fatoMarcanteInfancia_3", pergunta: "Relate algum fato marcante em sua infância.", tipo: "SIMPLES" },
];

const perguntasBd4 = [
    { name: "medosHoje_4", pergunta: "Quais são seus maiores medos hoje?", tipo: "SIMPLES" },
    { name: "pensamentoSobreSi_4", pergunta: "O que você pensa a seu respeito?", tipo: "SIMPLES" },
    { name: "priRelaAmo_4", pergunta: "Como foi seu primeiro relacionamento amoroso?", tipo: "SIMPLES" },
    { name: "papelNaVida_4", pergunta: "Se você avaliasse sua situação na vida, qual papel que mais caberia a você hoje? Vítima, Responsável?", tipo: "SIMPLES" },
    { name: "desempPapelViti_4", pergunta: "Em quais situações você desempenha o papel de vítima?", tipo: "SIMPLES" },
    { name: "desempPapelResp_4", pergunta: "Em quais situações você desempenha o papel de responsável?", tipo: "SIMPLES" },
    { name: "vitoriosa_4", pergunta: "Você se considera vitoriosa (o) ou derrotada (o)?", tipo: "SIMPLES" },
    { name: "preferDomSub_4", pergunta: "Nos relacionamentos e na vida, você prefere ser Dominante ou Submisso?", tipo: "SIMPLES" },
    { name: "punicaoProblemas_4", pergunta: "Quem deve ser punido por problemas que ocorrem com você? Ou Quem é culpado por seus problemas pessoais?", tipo: "SIMPLES" },
    { name: "raivaOuRancor_4", pergunta: "Sente raiva ou rancor de alguém? De quem?", tipo: "SIMPLES" },
    { name: "pressaoAtualidade_4", pergunta: "Sente-se de alguma forma pressionada (o) na atualidade? De que maneira?", tipo: "SIMPLES" },
    { name: "pessoaControladora_4", pergunta: "Você se acha uma pessoa controladora?", tipo: "SIMPLES" },
    { name: "sentimInfer_4", pergunta: "Sente-se de alguma forma inferior aos outros? Por quê?", tipo: "SIMPLES" },
    { name: "duvidaCapacidade_4", pergunta: "Duvida da sua própria capacidade?", tipo: "SIMPLES" },
    { name: "audaAutoProt_4", pergunta: "Você é audaciosa (o), corre atrás de suas metas ou você é auto protetor(a) e prefere se poupar de eventuais riscos?", tipo: "SIMPLES" },
    { name: "culpaSentimento_4", pergunta: "Existe algo que o faz sentir-se culpado? O que exatamente?", tipo: "SIMPLES" },
];

const perguntasBd5 = [
    {
        name: "matriz", pergunta: "Quais sentimentos mais comuns em você hoje? Com que intensidade você os sente?", tipo: "MATRIZ",
        horizontal: ["Pouca|P", "Média|M", "Muita|G"],
        vertical: [
            "Raiva|raiva", "Medo de algo concreto|medo", "Medos vagos|medovago", "Culpa|culpa", "Revolta|revolta", "Medo de perder o controle|medocontrole",
            "Tristeza|trizteza", "Mágoa|magoa", "Orgulho|orgulho", "Ódio|odio", "Egoísmo|egoismo", "Ansiedade|ansiedade", "Intolerância|intole", "Submissão|submi", "Indecisão|indecisao",
            "Desespero|desespero", "Desânimo|desanimo", "Covardia|covardia", "Egocentrismo|ego", "Ciúme|ciume", "Frustração|frustracao", "Nostalgia|nostal",
            "Cansaço|cansaco", "Impaciência|impaciencia", "Angústia|angustia", "Timidez|timidez", "Apatia|apatia", "Solidão|solidao", "Autoritarismo|autorita", "Ressentimento|ressentimento"
        ]
    }
]

const personalizado = [
    { tipo: "SIMPLES", pergunta: "Qual o seu nome?" },
    { tipo: "MULTIPLA_ESCOLHA", pergunta: "Como deve ser o resultado?", params: ["Opção1", "Opção2"] }
]

const ACESSO_PRIVADO = 0;
const ACESSO_PUBLICO = 1;

const Pergunta = ({ name, pergunta, handler, initData }) => {
    const [dados, setDados] = useState(initData[name])

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setDados(value);
        handler(name, value);
    }

    useEffect(() => {
        setDados(initData[name])
    }, [initData])

    return (
        <FormItem label={pergunta} className="mb-8">
            <Input type="text" name={name} value={dados} onChange={handleInputChange} maxLength={600} />
        </FormItem>
    )
}

const Anamnese = ({ acesso = ACESSO_PRIVADO, idFormulario }) => {
    let { idCliente } = useParams();
    const [dados, setDados] = useState({})
    const navigate = useNavigate();
    const { Tr, Th, Td, THead, TBody } = Table;
    const [step, setStep] = useState(0)

    const setDadosFromVar = (name, value) => {
        setDados({
            ...dados,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (acesso === ACESSO_PRIVADO) {
            const response = await _salvarQuestionarioTecnica({
                resultado: JSON.stringify(dados),
                clienteId: idCliente,
                tecnicaId: TRG.id
            })

            navigate(`/cliente/${idCliente}`)
        }
        else {
            try {
                await salvarFormularioAcessoCliente();
                toast.push(<Notification title={'Formulário salvo com sucesso!'} type="success" />, {
                    placement: 'top-center',
                })
            }
            catch (er) {
                toast.push(<Notification title={'Não foi possível salvar as informações do formulário!'} type="warning" />, {
                    placement: 'top-center',
                })
            }


        }
    }

    const salvarFormularioAcessoCliente = async () => {
        try {
            const response = await _salvarQuestionarioTecnica({
                resultado: JSON.stringify(dados),
                id: idFormulario
            });
            setFim(true);
        }
        catch (_) {
            toast.push(<Notification title={'Não foi possível salvar as informações do formulário!'} type="warning" />, {
                placement: 'top-center',
            })
        }

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setDados({
            ...dados,
            [name]: value
        });
    }

    function buscarNome(valor) {
        return valor.split("|")[0];
    }

    function buscarValor(valor) {
        return valor.split("|")[1];
    }

    const voltarStep = () => {
        setStep(step - 1);
        window.scrollTo(0, 0);
    }

    const avancarStep = () => {
        setStep(step + 1);
        window.scrollTo(0, 0);

        //TODO: Salvar dados do step atual
    }

    return (
        <>
            <div className="px-2">
                <div className="px-2">
                    <div className="">
                        <div className="flex-auto py-4">
                            <h1>Anamnese TRG</h1>
                        </div>
                        {
                            acesso === ACESSO_PRIVADO &&
                            <div className="flex-auto flex justify-end">
                                <Button
                                    size="md" shape="circle" variant="" className="mb-2 z-50"
                                    type="button"
                                    onClick={() => { navigate(`/cliente/${idCliente}?pg=tecnicas`) }}
                                    icon={<FiX />}
                                />
                                {/* <Button variant="solid" onClick={() => { navigate(`/cliente/${idCliente}`) }}>Voltar</Button> */}
                            </div>
                        }

                    </div>
                    <form onSubmit={handleSubmit}>
                        <FormContainer>
                            <div className={step == 0 ? "" : "hidden"}>
                                {
                                    perguntasBd.map(item => (
                                        <Pergunta key={item.name} name={item.name} pergunta={item.pergunta} handler={setDadosFromVar} initData={dados} />
                                    ))
                                }
                            </div>

                            <div className={step == 1 ? "" : "hidden"}>
                                <h3>Fase 02 - Mental</h3>

                                {
                                    perguntasBd2.map(item => (
                                        <Pergunta key={item.name} name={item.name} pergunta={item.pergunta} handler={setDadosFromVar} initData={dados} />
                                    ))
                                }
                            </div>

                            <div className={step == 2 ? "" : "hidden"}>
                                <h3>Fase 03 - Infância</h3>
                                {
                                    perguntasBd3.map(item => (
                                        <Pergunta key={item.name} name={item.name} pergunta={item.pergunta} handler={setDadosFromVar} initData={dados} />
                                    ))
                                }
                            </div>

                            <div className={step == 3 ? "" : "hidden"}>
                                <h3>Fase 04 - Emocional</h3>
                                {
                                    perguntasBd4.map(item => (
                                        <Pergunta key={item.name} name={item.name} pergunta={item.pergunta} handler={setDadosFromVar} initData={dados} />
                                    ))
                                }
                            </div>

                            <div className={step == 3 ? "" : "hidden"}>
                                {
                                    dados &&
                                    perguntasBd5.map(item => (
                                        <FormItem label="Você sente frustração em relação a:">
                                            <Table>
                                                <THead>
                                                    <Tr>
                                                        <Td></Td>
                                                        {
                                                            item.horizontal.map(h_item => (
                                                                <Td className="text-center">{buscarNome(h_item)}</Td>
                                                            ))
                                                        }
                                                    </Tr>
                                                </THead>
                                                <TBody >

                                                    {
                                                        item.vertical.map((v_item, idx) => (
                                                            <Tr>
                                                                <Td>{buscarNome(v_item)}</Td>
                                                                {
                                                                    item.horizontal.map((h_item, idx2) => (
                                                                        <Td className="text-center">
                                                                            <input checked={dados[`q_${idx}`] === buscarValor(h_item)} key={`q_${idx}_${idx2}`} name={`q_${idx}`} type="radio" value={buscarValor(h_item)} onChange={handleInputChange} />
                                                                        </Td>
                                                                    ))
                                                                }

                                                            </Tr>
                                                        ))
                                                    }
                                                </TBody>
                                            </Table>
                                        </FormItem>
                                    ))
                                }
                            </div>
                            <div className="text-center gap-2 flex">
                                <Button disabled={step > 0 ? false : true} variant="solid" type="button" onClick={voltarStep}>Voltar</Button>
                                <Button disabled={step < 3 ? false : true} variant="solid" type="button" onClick={avancarStep}>Avançar</Button>
                                <Button disabled={step == 3 ? false : true} variant="solid" type="submit">Enviar</Button>
                            </div>
                        </FormContainer>
                    </form>

                </div>
            </div>
        </>
    )

}

export default Anamnese;
import React from "react";
import { criarFormatador } from "utils/moneyformatter";
import { formatCPF } from "utils/format";
import { PaymentDetails } from "./PaymentDetails";
import "./styles.css";


export const PurchaseDetails = ({ clientData }) => {
  const formatador = criarFormatador();

  const nomeProfissional = clientData?.nomeProfissional || "";
  const cpfProfissional = formatCPF(clientData?.cpfProfissional);
  const nomeCliente = clientData?.cliente.nome;
  const cpfCliente = formatCPF(clientData?.cliente.cpf);
  const emailCliente = clientData?.cliente.email;
  const valorPagamento = clientData?.pagamento.valor;
  const fotoUrl = clientData?.urlLogo ? clientData?.urlLogo : clientData?.urlFoto;

  return (
    <div className="purchase-info">
      <h4 className="text-indigo-800">Informações do Pagamento</h4>
      <div className="client-container">
        <div className="client-item">
          <span className="client-label">Cliente: </span>
          <span className="client-value">{nomeCliente}</span>
        </div>
        {cpfCliente &&
          <div className="client-item">
            <span className="client-label">CPF: </span>
            <span className="client-value">{cpfCliente}</span>
          </div>
        }
        {emailCliente &&
          <div className="client-item">
            <span className="client-label">Email: </span>
            <span className="client-value">{emailCliente}</span>
          </div>
        }
      </div>
      <div className="icon-container">
        {fotoUrl ? (
          <img
            src={fotoUrl}
            alt="Foto do Profissional"
            className="profile-image"
          />
        ) : (
          <div className="placeholder-image"></div>
        )}
      </div>
      <div className="professional-container">
        <div className="professional-item">
          <span className="professional-label">Profissional:</span>
          <span className="professional-value">{nomeProfissional}</span>
        </div>
        <div className="professional-item">
          <span className="professional-label">CPF:</span>
          <span className="professional-value">{cpfProfissional}</span>
        </div>
      </div>
      <div className="product-info">
        <div className="product-item">
          <p className="product-description">{clientData?.pagamento?.titulo}</p>
        </div>
      </div>
      <hr className="separator" />
      <div className="total-container">
        <span className="total-label">TOTAL</span>
        <span className="total-value">
          {valorPagamento !== undefined ? formatador.format(valorPagamento) : ""}
        </span>
      </div>
      <div>
        <PaymentDetails clientData={clientData} />
      </div>
      <div className="due-date-container">
        <p>Data de vencimento</p>
        <strong>{clientData?.pagamento?.dataVencimento}</strong>
      </div>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    cliente: {}
}

export const clienteSlice = createSlice({
    name: 'base/cliente',
    initialState,
    reducers: {
        setCliente: (state, action) => {
            state.cliente = action.payload
        }
    },
})

export const { setCliente } = clienteSlice.actions

export default clienteSlice.reducer

import { useState } from "react";
import { Card, Drawer, Button } from "components/ui";
import { HiPlusCircle } from "react-icons/hi";
import { _buscarRelacionamentoClienteById } from "services/PacienteService";
import ContactList from "./components/ContactList";
import { ContactForm } from "./components/Form/ContactForm";

export function Contatos({
  onBack,
  isSubmiting,
  handleFinish,
  list,
  handleDeleteContact,
  onAddContact,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRelationshipClient, setSelectedRelationshipClient] =
    useState(null);

  const handleDrawOpen = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <h5 className="font-light mt-8">Contatos</h5>
      <Card>
        <ContactList
          contacts={list}
          handleDeleteContact={(index) => handleDeleteContact(index)}
        />
      </Card>

      <Button
        className="mx-4 shadow-lg m-4"
        variant="solid"
        size="sm"
        icon={<HiPlusCircle />}
        onClick={handleDrawOpen}
      >
        Adicionar Contato
      </Button>
      <Drawer
        title="Adicionar Contato"
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        onRequestClose={closeDrawer}
        height="100%"
        placement={window.innerWidth < 768 ? "bottom" : "right"}
      >
        <ContactForm
          onSubmit={(values) => onAddContact(values)}
          handleTableToolsClick={() => setSelectedRelationshipClient(null)}
          selectedClient={selectedRelationshipClient}
        />
      </Drawer>

      <div className="w-full flex justify-between mt-4 gap-2">
        <Button onClick={onBack}>Voltar</Button>
        <Button
          type="button"
          variant="solid"
          onClick={() => handleFinish(list)}
          loading={isSubmiting}
        >
          Enviar
        </Button>
      </div>
    </>
  );
}

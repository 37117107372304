import { useState, useEffect } from 'react';
import ContactListMobile from './mobile';
import ContactListDesktop from './desktop';

export default function ContactList({ contacts, handleDeleteContact }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {isMobile ? (
                <ContactListMobile
                    contacts={contacts}
                    handleDeleteContact={handleDeleteContact}
                />
            ) : (
                <ContactListDesktop
                    contacts={contacts}
                    handleDeleteContact={handleDeleteContact}
                />
            )}
        </div>
    );
}

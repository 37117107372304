import React from "react";
import dayjs from "dayjs";

const AgendamentoRealizado = ({ data, horaUsuario, servicoEscolhido, localidadeEscolhida }) => {
  return (
    <div className="flex flex-col gap-4 w-[300px] my-32">
      <div className="flex-col">
        <p className="font-bold text-md text-indigo-800">
          Agendamento realizado com sucesso
        </p>
        <p>
          <span className="font-bold">Data:</span>{" "}
          {dayjs(data).format("DD/MM/YYYY")}
        </p>
        <p>
          <span className="font-bold">Horário:</span> {horaUsuario}
        </p>
        <p>
          <span className="font-bold">Serviço:</span> {servicoEscolhido.nome}
        </p>
        <p>
          <span className="font-bold">Local:</span> {localidadeEscolhida.nome}
        </p>
        <p>{localidadeEscolhida.endereco}</p>
      </div>
    </div>
  );
};

export default AgendamentoRealizado;

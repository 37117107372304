import ApiService from './ApiService'

export async function _validarCadastro(uuid) {
    return ApiService.fetchData({
        url: `/rest/init/validacao/${uuid}`,
        method: 'get',
    })
}

export async function _agendar(data) {
    return ApiService.fetchData({
        url: `/rest/init/agenda`,
        method: 'post',
        data,
    })
}

export async function _buscarQuestionarioCliente(formularioId) {
    return ApiService.fetchData({
        url: `/rest/init/formulario/${formularioId}`,
        method: 'get',
    })
}

export async function _salvarQuestionario(data) {
    return ApiService.fetchData({
        
        url: `/rest/init/formulario`,
        method: 'post',
        data,
    })
}

export async function _salvarQuestionarioTecnica(data) {
    return ApiService.fetchData({
        
        url: `/rest/init/tecnica`,
        method: 'post',
        data,
    })
}

export async function _confirmarAgenda(uuidAgenda) {
    return ApiService.fetchData({
        url: `/rest/init/agenda/${uuidAgenda}/confirmar`,
        method: 'post'
    })
}

export async function _reagendarAgenda(uuidAgenda) {
    return ApiService.fetchData({
        url: `/rest/init/agenda/${uuidAgenda}/reagendar`,
        method: 'post'
    })
}

export async function _cancelarAgenda(uuidAgenda) {
    return ApiService.fetchData({
        url: `/rest/init/agenda/${uuidAgenda}/cancelar`,
        method: 'post'
    })
}

export async function _buscarAgendaByUuid(agendaUuid) {
    return ApiService.fetchData({
        url: `/rest/init/agenda/${agendaUuid}/status`,
        method: 'get',
    })
}
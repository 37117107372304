import React from "react";
import { Button, Dialog } from "components/ui";

const FusoMessage = ({ isOpen, onClose }) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className="p-6 text-center">
        <h3 className="text-lg font-bold text-indigo-800 mb-4">
          Aviso de Fuso Horário
        </h3>
        <p>
          O fuso horário selecionado para o agendamento é diferente do fuso
          horário do profissional. Por favor, certifique-se de que o horário
          escolhido seja o mais conveniente para você.
        </p>
        <div className="mt-6">
          <Button onClick={onClose} className="bg-indigo-800 text-white">
            Entendi
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default FusoMessage;

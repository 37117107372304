import React, { useState } from 'react';
import { Dialog } from 'components/ui';
import { criarFormatador } from 'utils/moneyformatter';
import "./styles.css";

export const PaymentDetails = ({ clientData }) => {
  const formatador = criarFormatador();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const lancamentos = clientData?.lancamentos || [];
  const pagamento = clientData?.pagamento || {};

  const totalValorPraticado = lancamentos.reduce((acc, lancamento) => {
    return acc + (lancamento.valorPraticado || 0);
  }, 0);

  const taxaCorpora = Math.abs(totalValorPraticado - (pagamento.valor || 0));

  const formatDate = (dateString) => {
    return dateString.split(' ')[0];
  };

  return (
    <>
      <button onClick={handleOpenDialog} className="details-payment-button">
        Detalhes da cobrança
      </button>

      <Dialog
        isOpen={isOpen}
        onClose={handleCloseDialog}
        onRequestClose={handleCloseDialog}
      >
        <h4>Detalhes da Cobrança</h4>
        <div className="lancamento-list-container mt-4">
          {lancamentos.length > 0 ? (
            lancamentos.map((lancamento) => (
              <div key={lancamento.uuid} className="lancamento-item">
                <p><strong>Descrição:</strong> {lancamento.descricao}</p>
                <p><strong>Observação:</strong> {lancamento.observacao}</p>
                <p><strong>Data:</strong> {formatDate(lancamento.data)}</p>
                <p><strong>Valor Compra:</strong> {formatador.format(lancamento.valorCompra)}</p>
                <hr />
              </div>
            ))
          ) : (
            <p>Não há lançamentos disponíveis.</p>
          )}
        </div>

        <div className="total-section mt-4">
          <p><strong>Taxa Corpora:</strong> {formatador.format(taxaCorpora)}</p>
          <p><strong>Valor Total:</strong> {formatador.format(pagamento.valor)}</p>
        </div>
      </Dialog>
    </>
  );
};

import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    appState: '',
    lid: '', //lancamento Id
    dadosPesquisa: [],
    currentRouteKey: '',
    nomeCliente: "",
    cid: "", //cliente id
    dataHora: "",
    game:  [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
      ],
}

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setGame: (state, action) => {
            state.game = action.payload
        },
        setNomeCliente: (state, action) => {
            state.nomeCliente = action.payload
        },
        setCodigoCliente: (state, action) => {
            state.cid = action.payload
        },
        setDataHora: (state, action) => {
            state.dataHora = action.payload
        },
        setLancamentoId: (state, action) => {
            state.lid = action.payload
        },
        stIniciarAgendamento: (state, action) => {
            state.lid = action.payload,
            state.appState = 'INICIAR_AGENDA'
        },
        stEncerrarAgendamento: (state, action) => {
            state.lid = "",
            state.appState = ''
        },
        setAppState: (state, action) => {
            state.appState = action.payload
        },
        setDadosPesquisa: (state, action) => {
            state.dadosPesquisa = action.payload
        }
    },
})

export const { setDadosPesquisa, stEncerrarAgendamento, stIniciarAgendamento, setCurrentRouteKey, setGame, setNomeCliente, setCodigoCliente, setDataHora, setLancamentoId, setAppState} = commonSlice.actions

export default commonSlice.reducer

import React, { useRef, useState, useEffect } from 'react'
import {
    Drawer,
    Button,
    Input,
    Card,
    Select,
    Radio,
    Tag,
    Notification,
    toast,
    FormContainer,
    Dialog,
    Table,
    FormItem
} from 'components/ui'
import { _buscarDesignTeste, _buscarDesignFormulario, _salvarFormularioCliente } from 'services/FormularioService';
import { useParams, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import GeradorTecnicas from './GeradorTecnicas';
import GeradorTecnicasMetricasAdicionais from './GeradorTecnicasMetricasAdicionais';

const TecnicasPsicologia = ({design, idFormulario, idCliente}) => {
    const [modelo, setModelo] = useState(0);
    const [tecnica, setTecnica] = useState(design);

    if (tecnica.modelo === 1)
    return (
        <GeradorTecnicas info={tecnica} idCliente={idCliente} idFormulario={idFormulario} />
    )

    else if (tecnica.modelo === 2)
    return (
        <GeradorTecnicasMetricasAdicionais info={tecnica} idCliente={idCliente} idFormulario={idFormulario} />
    )

    else if (tecnica.modelo === 3)
    return (
        <GeradorTecnicas info={tecnica} idCliente={idCliente} idFormulario={idFormulario} />
    )
}

export default TecnicasPsicologia;
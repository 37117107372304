import { AiOutlineUser } from "react-icons/ai";
import { PiTrashDuotone, PiWhatsappLogo } from "react-icons/pi";
import ReactTooltip from "react-tooltip";
import { formatWhatsNumber } from "utils/format";
import { Link } from "react-router-dom";

function ContactListMobile({ contacts, handleDeleteContact }) {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {contacts.map((contact, index) => (
        <div key={index} className="bg-white shadow-md rounded-md p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              {contact.clienteRelacionado && (
                <Link
                  reloadDocument
                  to={`/cliente/${contact.clienteRelacionado.id}?pg=profile`}
                  className="text-indigo-600 mr-2 cursor-pointer"
                >
                  <AiOutlineUser
                    data-tip="Ver perfil"
                    data-place="top"
                    data-offset="{'top': -15}"
                    data-effect="solid"
                    className="w-4 h-4"
                  />
                </Link>
              )}
              <div className="text-sm font-medium text-gray-400">
                {contact.clienteRelacionado
                  ? contact.clienteRelacionado.nome
                  : contact.nome}
              </div>
            </div>
            <div
              onClick={() => handleDeleteContact(index)}
              className="cursor-pointer text-red-400 hover:text-red-700"
            >
              <PiTrashDuotone
                className="w-5 h-5 outline-none"
                data-tip="Deletar"
                data-effect="solid"
                data-place="top"
                data-offset="{'top': -15}"
              />
              <ReactTooltip
                backgroundColor="none"
                textColor="#9CA3AF"
                fontSize="12px"
              />
            </div>
          </div>
          <div className="text-sm text-gray-400 mb-2">
            <div className="flex items-center ">
              <p className="mr-2">Telefone: </p>{" "}
              <PiWhatsappLogo className="mr-1" color="#128C7E" />
              <a
                className=" cursor-pointer text-sm flex items-center  hover:text-gray-500"
                target="_blank"
                href={`https://wa.me/${formatWhatsNumber(
                  contact.clienteRelacionado
                    ? contact.clienteRelacionado.telefone
                    : contact.telefone
                )}`}
                rel="noreferrer"
              >
                {contact.clienteRelacionado
                  ? contact.clienteRelacionado.telefone
                  : contact.telefone}
              </a>
            </div>
          </div>
          <div className="text-sm text-gray-400">
            Relação: {contact.relacao}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ContactListMobile;

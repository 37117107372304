import { Button, Dialog } from "components/ui";
import { useEffect, useRef, useState } from "react";
import { formatNumber } from "utils/format";

import "react-quill/dist/quill.snow.css";
import { useReactToPrint } from "react-to-print";

export default function ModalView({ isOpen, onClose, data, qrCodeBase64 }) {
  const { documento } = data;
  const [logo, setLogo] = useState("");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@page { size: auto;  margin: 25mm; } }",
  });

  useEffect(() => {
    async function getLogo() {
      const imageUrl = data.urlLogo || data.urlFoto;
      fetch(imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64data = reader.result;
            setLogo(base64data);
          };
        });
    }
    if (data.urlLogo || data.urlFoto) {
      getLogo();
    }
  }, [isOpen, data.urlLogo, data.urlFoto]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      onRequestClose={onClose}
      className="h-full max-h-[85%]"
    >
      <h4 className="text-gray-700">{data.documento.titulo}</h4>

      <div className="h-[90%] overflow-y-auto p-4">
        <div ref={componentRef} className="w-[180mm]  mx-auto bg-white">
          <div className="flex justify-between mb-3">
            <img className="h-[80px]" src={logo} alt="" />
            <div>
              <p>{data.nome || ""}</p>
              <p>{data.email || ""}</p>
              <p>{formatNumber(data.telefone) || ""}</p>
              <p>{data.endereco || ""}</p>
            </div>
          </div>

          <hr />

          <div className="min-h-[300px] my-3">
            <div
              className="tborder-rich-text"
              dangerouslySetInnerHTML={{ __html: documento.texto }}
            />
          </div>

          <hr />

          <div className="flex justify-between mt-3">
            <div className="text-left">
              <p>Este documento foi gerado por</p>
              <p>Corpora - Saúde Inteligente</p>
              <p>e pode ser verificado no QRCode ao lado.</p>
            </div>
            <img className="w-[100px]" alt="" src={qrCodeBase64} />
          </div>
        </div>
      </div>

      <center className="pt-5">
        <Button onClick={handlePrint}>Imprimir</Button>
      </center>
    </Dialog>
  );
}

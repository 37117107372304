// App.js ou o arquivo que contém suas rotas

import React, { Suspense } from "react";
import { Loading } from "components/shared";
import { Routes, Route } from "react-router-dom";

import QuestionarioCliente from "./tecnicas/QuestionarioCliente";
import ConfirmacaoAgenda from "views/calendario/ConfirmacaoAgenda";
import ReagendarAgenda from "views/calendario/ReagendarAgenda";
import Documentos from "./Documentos";
import { Checkout } from "./checkout";
import PaymentStatus from "./checkout/PaymentStatus";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CalendarioPublico from "./calendario/components/CalendarioPublico";
import Paciente from "./Paciente";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const LandingPage = () => {
  window.location.href = "https://www.usecorpora.com.br";
  return <></>;
};

const AllRoutes = (props) => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/form/:idFormulario/:idCliente"
        element={<QuestionarioCliente />}
      />
      <Route path="/diario/:idFormulario" element={<QuestionarioCliente />} />
      <Route
        path="/agenda/confirmar/:uuidAgenda"
        element={<ConfirmacaoAgenda />}
      />
      <Route
        path="/agenda/reagendar/:uuidAgenda"
        element={<ReagendarAgenda />}
      />
      <Route path="/:idCliente" element={<CalendarioPublico />} />
      <Route path="/:idCliente/pay/:hash" element={<Checkout />} />
      <Route
        path="/:idCliente/pay/:hash/payment-status"
        element={
          <Elements stripe={stripePromise}>
            <PaymentStatus />
          </Elements>
        }
      />
      <Route path="/documento/:uuidDocumento" element={<Documentos />} />
      <Route
        path="/:urlAgenda/paciente/atualizar/:uuidPaciente"
        element={<Paciente />}
      />
      <Route
        path="/:urlAgenda/paciente/cadastro/:uuidProfissional"
        element={<Paciente />}
      />
    </Routes>
  );
};

const Views = (props) => {
  return (
    <Suspense fallback={<Loading loading={true} />}>
      <AllRoutes {...props} />
    </Suspense>
  );
};

export default Views;

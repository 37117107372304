import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import "./styles.css";
import { CardPayment } from "../CardPayment";

export const ErrorPage = ({ isPix }) => {
  
  const errorContent = (
    <div className="error-container">
      <div className="error-header">
        <FaExclamationCircle className="error-icon" />
        <h1>Ocorreu um Erro!</h1>
      </div>
      <div className="error-body">
        <p>Infelizmente, houve um problema ao processar seu pagamento.</p>
        <p>Por favor, tente novamente mais tarde.</p>
      </div>
      <button
        className="home-button"
        onClick={() => (window.location.href = "/")}
      >
        Tentar novamente
      </button>
    </div>
  );

  if (!isPix) {
    return <CardPayment>{errorContent}</CardPayment>;
  }

  return errorContent;
};

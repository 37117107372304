import React from "react";
import { Dialog, Button } from "components/ui";

const ConfirmationDialog = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className="p-4">
        <h4 className="text-lg font-bold text-indigo-800 mb-2">Pagamento Antecipado</h4>
        <p className="mb-4">Para agendamento, este serviço requer pagamento antecipado na reserva do horário. </p>
        <Button variant="solid" className="my-4 cursor-pointer border transition-colors hover:border-[#3730a3]" onClick={onConfirm}>
          Seguir
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;

import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import { useNavigate, useParams } from "react-router-dom";
import View from 'views'

const environment = process.env.NODE_ENV

function App(props) {
    return (
        <Provider store={store}>
            <BrowserRouter history={history}>
                {/* <div className="flex bg-gradient-to-r from-slate-100 to-white justify-center h-[100vh]"> */}
                <div className="flex bg-white justify-center">
                    <View {...props} />
                </div>
            </BrowserRouter>
        </Provider>
    )
}

export default App

import { Card } from "components/ui";
import securityImage from "assets/images/compra-segura.png";
import './styles.css'

export const CardPayment = ({ children }) => {
  return (
    <div className="checkout-container">
      <Card className="checkout-card">
        <div className="container-details">{children}</div>
        <div className="logo-container">
          <span>Powered by </span>
          <a
            href="https://www.usecorpora.com.br"
            className="company-link"
          >
            <img src="/img/logo/logo-light-full.png" width="120" alt="Company Logo" />
          </a>
          <img src={securityImage} className="security-image" alt="Security Badge" />
        </div>
      </Card>
    </div>
  );
};

import React from "react";
import "./styles.css"; 
import { CardPayment } from "../CardPayment";

const ProcessingPayment = () => {
  return (
    <CardPayment>
      <div className="loading-container">
        <div className="spinner"></div>
        <h2>Processando seu pagamento...</h2>
        <p>Por favor, aguarde enquanto confirmamos sua transação.</p>
      </div>
    </CardPayment>
  );
};

export default ProcessingPayment;

import React from "react";
import { PaymentInfo } from "./components/PaymentInfo";
import { PurchaseDetails } from "./components/PurchaseDetails";
import "./styles.css";

export const InitialDetails = ({ onProceedToPayment, clientData }) => {
  return (
    <div className="container-initial-details">
      <PurchaseDetails clientData={clientData} />
      <div className="separator-line"></div>
      <PaymentInfo
        onProceedToPayment={onProceedToPayment}
        clientData={clientData}
      />
    </div>
  );
};

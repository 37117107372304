import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormContainer, FormItem, Input, Button, Select } from "components/ui";
import { PhoneInput } from "react-international-phone";
import { validateForm } from "./validateForm";

export function ContactForm({
  onSubmit,
  onSelectClient,
  selectedClient,
  handleTableToolsClick,
}) {
  const handleSelectClient = () => {
    onSelectClient();
  };

  return (
    <Formik
      initialValues={{
        nome: selectedClient ? selectedClient.nome : "",
        telefone: selectedClient ? selectedClient.telefone : "",
        relacao: "",
      }}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
      validate={validateForm}
    >
      {({ isSubmitting, resetForm, setValues }) => (
        <>
          <Form className="h-full">
            <FormContainer className="h-full flex flex-col">
              <p className=" text-gray-500 mb-2">
                Adicione um contato ou relacione a um cliente existente
              </p>
              <div className="h-full w-full max-h-72">
                <FormItem label="Nome do Contato">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="nome"
                    placeholder="Nome"
                    component={Input}
                    readOnly={!!selectedClient}
                  />
                </FormItem>
                <FormItem label="Telefone">
                  <Field name="telefone">
                    {({ field }) => (
                      <div>
                        <PhoneInput
                          inputStyle={{
                            fontSize: "15px",
                            color: "gray",
                            border: "none",
                          }}
                          countrySelectorStyleProps={{
                            buttonStyle: { border: "none" },
                          }}
                          className="input p-1"
                          defaultCountry="br"
                          name="phone"
                          value={field.value}
                          onChange={(value) => {
                            field.onChange({
                              target: {
                                name: field.name,
                                value: value,
                              },
                            });
                          }}
                          readOnly={!!selectedClient}
                        />
                      </div>
                    )}
                  </Field>
                </FormItem>
                <FormItem label="Relação">
                  {selectedClient ? (
                    <Field
                      as="select"
                      autoComplete="off"
                      name="relacao"
                      placeholder="Relação"
                      className="w-full cursor-pointer rounded border border-gray-300 focus:outline-none focus:border-blue-500 p-2"
                    >
                      <option value="">Selecione...</option>
                      <option value="Pai">Pai</option>
                      <option value="Mãe">Mãe</option>
                      <option value="Responsável">Responsável</option>
                      <option value="Padrastro">Padrasto</option>
                      <option value="Madrasta">Madrastra</option>
                      <option value="Parente">Parente</option>
                      <option value="Outro">Outro</option>
                    </Field>
                  ) : (
                    <Field
                      type="text"
                      autoComplete="off"
                      name="relacao"
                      placeholder="Terapeuta, Psicólogo, Cardiologista... "
                      component={Input}
                    />
                  )}
                </FormItem>
              </div>
              <div className="mt-auto flex flex-col justify-center gap-4">
                <Button
                  block
                  variant="solid"
                  type="submit"
                  loading={isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Salvar"}
                </Button>
                <Button
                  block
                  variant="solid"
                  onClick={() => {
                    resetForm();
                    setValues({
                      nome: "",
                      telefone: "",
                      relacao: "",
                    });
                    handleTableToolsClick();
                  }}
                >
                  Limpar
                </Button>
              </div>
            </FormContainer>
          </Form>
        </>
      )}
    </Formik>
  );
}

import { useParams } from "react-router-dom";
import AtualizarPaciente from "./AtualizarPaciente";
import CadastrarPaciente from "./CadastrarPaciente";

export default function Paciente() {
  let { uuidPaciente, uuidProfissional, urlAgenda } = useParams();

  if (uuidPaciente)
    return (
      <AtualizarPaciente uuidPaciente={uuidPaciente} urlAgenda={urlAgenda} />
    );

  if (uuidProfissional)
    return (
      <CadastrarPaciente
        uuidProfissional={uuidProfissional}
        urlAgenda={urlAgenda}
      />
    );

  return <></>;
}

import React from "react";
import { Card, Button } from "components/ui";

const LocalidadeSelection = ({
  localidadesUnicas,
  setStep,
  agendaDisponivel,
  liberarAgenda,
  dadosTerapeuta,
  escolherLocalidadeClick
}) => {


  return (
    <div className="flex flex-col self-center">
      {agendaDisponivel && liberarAgenda && (
        <div className="flex flex-col gap-2">
          <p className="text-slate-800 font-bold">Local de Atendimento</p>
          {Object.keys(localidadesUnicas)
            .sort()
            .map((key) => (
              <Card
                key={key}
              className="cursor-pointer border transition-colors hover:border-[#3730a3] my-4"
                onClick={() => {
                  escolherLocalidadeClick(localidadesUnicas[key]); 
                  setStep(1); 
                }}
              >
                <p>{localidadesUnicas[key].nome}</p>
              </Card>
            ))}
        </div>
      )}
       <Button
        className="my-4 cursor-pointer border transition-colors hover:border-[#3730a3]"
        onClick={() => setStep(-1)}
      >
        Voltar
      </Button>
    </div>
  );
};

export default LocalidadeSelection;

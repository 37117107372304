import React, { useState, useEffect } from "react";
import { Button } from "components/ui";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const ReservaConfirmacao = ({ profissional, local, servico, horario, redirectUrl, idCliente, data }) => {
  const [timeLeft, setTimeLeft] = useState(20 * 60); 

  console.log(data);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const currentDate = dayjs(data).format("DD/MM/YYYY");

  return (
    <div className="flex flex-col items-center p-4 border rounded shadow-md">
      <h2 className="text-2xl font-bold text-indigo-800 mb-4">RESERVA REALIZADA</h2>
      <p className="text-lg mb-4">Efetue o pagamento para confirmar seu atendimento na data e hora reservada</p>
      <Link to={`/${idCliente}/pay/${redirectUrl}`} state={{ timeLeft }}>
        <Button variant="solid" className="text-md p-2 mb-4 rounded  shadow-md text-grey-900 font-bold border transition-colors hover:border-[#3730a3]">
          PAGAR
        </Button>
      </Link>
      
      <div className="text-left w-full mb-4">
        <p><strong>Profissional:</strong> {profissional}</p>
        <p><strong>Data:</strong> {currentDate}</p>
        <p><strong>Horário:</strong> {horario}</p>
        <p><strong>Local:</strong> {local}</p>
        <p><strong>Serviço:</strong> {servico}</p>
      </div>

      <div className="text-center mb-4">
        <h3 className="text-xl font-bold text-green-600">Tempo Restante: {formatTime(timeLeft)}</h3>
      </div>

      <div className="text-sm text-gray-600">
        <p>* Em caso de pagamento não efetuado, a reserva será expirada.</p>
      </div>
    </div>
  );
};

export default ReservaConfirmacao;

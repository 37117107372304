import React, { useRef, useState, useEffect } from 'react'
import { Button } from 'components/ui'
import { _salvarQuestionarioTecnica } from 'services/AuthService';
import { useParams, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

const GeradorTecnicas = ({ info, idCliente, idFormulario }) => {

    const [formRef, setFormRef] = useState([]);
    const [tecnica, setTecnica] = useState(info);
    const [step, setStep] = useState(0);
    const navigate = useNavigate();

    const respostaClick = (el, id) => {
        let _formRef = [...formRef];
        _formRef[id] = el.value;
        setFormRef(_formRef);
    }

    const salvar = () => {

        let dados = {
            id: idFormulario,
            resultado: JSON.stringify(formRef)
        }

        _salvarQuestionarioTecnica(dados);
        setStep(1);

    }

    if (step === 0)
        return (
            <>

                <h4 className="drop-shadow-md bg-indigo-800 text-white p-1 rounded">{tecnica.nome}</h4>

                {
                    tecnica.questionario.map((q, index) => (
                        <div key={index}>
                            <h4>{q.label}</h4>
                            {
                                q.perguntas.map((subPergunta) => (
                                    <div key={subPergunta.id} className="my-8">
                                        <p className="font-bold text-indigo-700">{subPergunta.id}. {subPergunta.label}</p>
                                        <div className="flex flex-col lg:flex-row drop-shadow-md">
                                            {
                                                tecnica.respostas.map((resposta) => (
                                                    <div className="m-2">
                                                        <Button size="xs" type="button" variant={parseInt(formRef[subPergunta.id]) === parseInt(resposta.value) ? "solid" : "twoTone"} onClick={(evt) => respostaClick(evt.target, subPergunta.id)} value={resposta.value}>{resposta.label}</Button>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }

                <Button variant="solid" onClick={() => salvar()}>Responder</Button>

            </>
        )
    else
        return (
            <>
            <p>Obrigado por responder!</p>
            </>
        )

}

export default GeradorTecnicas;
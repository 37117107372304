import React from "react";
import "./styles.css";
import { CardPayment } from "../CardPayment";

const LoadingPaymentInfo = () => {
  return (
    <CardPayment>
      <div className="loading-container">
        <div className="spinner"></div>
        <h2>Carregando informações de pagamento...</h2>
        <p>Aguarde um momento enquanto recuperamos os detalhes necessários.</p>
      </div>
    </CardPayment>
  );
};

export default LoadingPaymentInfo;
